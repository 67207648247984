<template>
    <div>
        <manage-ad-account-budget
            :key="item.dealerId"
            :dealer="item"
            icon-size="15px"
            :platform="platform"
            @update="onEditBudget" />
    </div>
</template>

<script>
import ManageAdAccountBudget from '@/components/globals/ManageAdAccountBudget';

export default {
    components: {
        ManageAdAccountBudget
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        platform: {
            type: String,
            required: true
        }
    },
    methods: {
        onEditBudget(budget) {
            this.$emit('callback-with-data', budget);
        }
    }
};
</script>
